<template>
  <section>
    <b-row>
      <b-col lg="5" cols="12">
        <b-card>
          <template v-if="this.policyCategory == 'salary-policy'">
            <b-card-text>
              <p class="text-uppercase custom-text-disabled">
                Salary Policy Details
              </p>
              <b-list-group flush>
                <b-list-group-item
                  ><div class="text-base">
                    Title:
                    <span class="text-body-2 font-weight-bold"
                      >{{ policyDetails?.title }} %</span
                    >
                  </div></b-list-group-item
                >
                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Basic (%):
                    <span class="text-body-2 font-weight-bold"
                      >{{ policyDetails?.basic }} %</span
                    >
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Rent (%):
                    <span class="text-body-2 font-weight-bold"
                      >{{ policyDetails?.house_rent }} %</span
                    >
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Conveyance (%):
                    <span class="text-body-2 font-weight-bold"
                      >{{ policyDetails?.conveyance }} %</span
                    >
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Medical (%):
                    <span class="text-body-2 font-weight-bold"
                      >{{ policyDetails?.medical }} %</span
                    >
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Entertainment (%):
                    <span class="text-body-2 font-weight-bold"
                      >{{ policyDetails?.entertainment }} %</span
                    >
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Created On:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.created_at)
                    }}</span>
                  </div></b-list-group-item
                >
              </b-list-group>
            </b-card-text>
          </template>
          <template
            v-else-if="this.policyCategory == 'project-commission-policy'"
          >
            <b-card-text>
              <p class="text-uppercase custom-text-disabled">
                Project Commission Policy Details
              </p>
              <b-list-group flush>
                <b-list-group-item
                  ><div class="text-base">
                    Title:
                    <span class="text-body-2 font-weight-bold">{{
                      policyDetails?.title
                    }}</span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Type:
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="policyDetails?.amount_value_type == 'flat'"
                      >
                        <b-badge class="ml-1" variant="light-success">
                          {{
                            formatAmountType(policyDetails?.amount_value_type)
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <b-badge class="ml-1" variant="light-warning">
                          {{
                            formatAmountType(policyDetails?.amount_value_type)
                          }}
                        </b-badge>
                      </template>
                    </span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Amount:
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="policyDetails?.amount_value_type == 'percentage'"
                      >
                        <b-badge class="ml-1" variant="light-primary">
                          {{
                            formatAmount(
                              policyDetails?.amount_value,
                              policyDetails?.amount_value_type
                            )
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <FormatCurrency
                          :currency="defaultCurrency"
                          :amount="policyDetails?.amount_value"
                        />
                      </template>
                    </span></div
                ></b-list-group-item>

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Created On:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.created_at)
                    }}</span>
                  </div></b-list-group-item
                >
              </b-list-group>
            </b-card-text>
          </template>
          <template v-else-if="this.policyCategory == 'meal-policy'">
            <b-card-text>
              <p class="text-uppercase custom-text-disabled">
                Meal Policy Details
              </p>
              <b-list-group flush>
                <b-list-group-item
                  ><div class="text-base">
                    Title:
                    <span class="text-body-2 font-weight-bold">{{
                      policyDetails?.title
                    }}</span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Type:
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="policyDetails?.amount_value_type == 'flat'"
                      >
                        <b-badge class="ml-1" variant="light-success">
                          {{
                            formatAmountType(policyDetails?.amount_value_type)
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <b-badge class="ml-1" variant="light-warning">
                          {{
                            formatAmountType(policyDetails?.amount_value_type)
                          }}
                        </b-badge>
                      </template>
                    </span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Amount:
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="policyDetails?.amount_value_type == 'percentage'"
                      >
                        <b-badge class="ml-1" variant="light-primary">
                          {{
                            formatAmount(
                              policyDetails?.amount_value,
                              policyDetails?.amount_value_type
                            )
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <FormatCurrency
                          :currency="defaultCurrency"
                          :amount="policyDetails?.amount_value"
                        />
                      </template>
                    </span></div
                ></b-list-group-item>

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Created On:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.created_at)
                    }}</span>
                  </div></b-list-group-item
                >
              </b-list-group>
            </b-card-text>
          </template>
          <template v-else-if="this.policyCategory == 'over-time-policy'">
            <b-card-text>
              <p class="text-uppercase custom-text-disabled">
                Over Time Policy Details
              </p>
              <b-list-group flush>
                <b-list-group-item
                  ><div class="text-base">
                    Title:
                    <span class="text-body-2 font-weight-bold">{{
                      policyDetails?.title
                    }}</span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Salary Type for Per Hour (Working Day):
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="
                          policyDetails?.working_day_amount_value_type == 'flat'
                        "
                      >
                        <b-badge class="ml-1" variant="light-success">
                          {{
                            (policyDetails?.working_day_amount_value_type).toUpperCase()
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <b-badge class="ml-1" variant="light-warning">
                          {{
                            (policyDetails?.working_day_amount_value_type).toUpperCase()
                          }}
                        </b-badge>
                      </template>
                    </span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Salary Value for Per Hour (Working Day):
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="
                          policyDetails?.working_day_amount_value_type ==
                          'percentage'
                        "
                      >
                        <b-badge class="ml-1" variant="light-primary">
                          {{
                            formatAmount(
                              policyDetails?.working_day_amount_value,
                              policyDetails?.working_day_amount_value_type
                            )
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <FormatCurrency
                          :currency="defaultCurrency"
                          :amount="policyDetails?.working_day_amount_value"
                        />
                      </template>
                    </span></div
                ></b-list-group-item>

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Salary Type for Per Hour (Off Day):
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="
                          policyDetails?.off_day_amount_value_type == 'flat'
                        "
                      >
                        <b-badge class="ml-1" variant="light-success">
                          {{
                            (policyDetails?.off_day_amount_value_type).toUpperCase()
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <b-badge class="ml-1" variant="light-warning">
                          {{
                            (policyDetails?.off_day_amount_value_type).toUpperCase()
                          }}
                        </b-badge>
                      </template></span
                    >
                  </div>
                </b-list-group-item>

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Salary Value for Per Hour (Off Day):
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="
                          policyDetails?.off_day_amount_value_type ==
                          'percentage'
                        "
                      >
                        <b-badge class="ml-1" variant="light-primary">
                          {{
                            formatAmount(
                              policyDetails?.off_day_amount_value,
                              policyDetails?.off_day_amount_value_type
                            )
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <FormatCurrency
                          :currency="defaultCurrency"
                          :amount="policyDetails?.off_day_amount_value"
                        />
                      </template>
                    </span></div
                ></b-list-group-item>

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Created On:
                    <span class="text-body-2 font-weight-bold">
                      {{ formatDate(policyDetails?.created_at) }}
                    </span>
                  </div></b-list-group-item
                >
              </b-list-group>
            </b-card-text>
          </template>

          <template v-else-if="this.policyCategory == 'insurance-policy'">
            <b-card-text>
              <p class="text-uppercase custom-text-disabled">
                Insurance Policy Details
              </p>
              <b-list-group flush>
                <b-list-group-item
                  ><div class="text-base">
                    Title:
                    <span class="text-body-2 font-weight-bold">{{
                      policyDetails?.title
                    }}</span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Salary Type:
                    <span class="text-body-2 font-weight-bold">
                      <b-badge class="ml-1" variant="light-success">
                        {{ formatSalaryType(policyDetails?.salary_type) }}
                      </b-badge>
                    </span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Employee Amount:
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="policyDetails?.amount_value_type == 'percentage'"
                      >
                        <b-badge class="ml-1" variant="light-primary">
                          {{
                            formatAmount(
                              policyDetails?.amount_value,
                              policyDetails?.amount_value_type
                            )
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <FormatCurrency
                          :currency="defaultCurrency"
                          :amount="policyDetails?.amount_value"
                        />
                      </template>
                    </span></div
                ></b-list-group-item>

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Employer Amount:
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="policyDetails?.amount_value_type == 'percentage'"
                      >
                        <b-badge class="ml-1" variant="light-primary">
                          {{
                            formatAmount(
                              policyDetails?.employer_amount_value,
                              policyDetails?.amount_value_type
                            )
                          }}
                        </b-badge>
                      </template>

                      <template v-else>
                        <FormatCurrency
                          :currency="defaultCurrency"
                          :amount="policyDetails?.employer_amount_value"
                        />
                      </template>
                    </span></div
                ></b-list-group-item>

                <b-list-group-item
                  ><div class="text-base">
                    Month:
                    <span class="text-body-2 font-weight-bold">{{
                      policyDetails?.month
                    }}</span>
                  </div></b-list-group-item
                >

                <!-- <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Start Date:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.start_date)
                    }}</span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    End Date:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.end_date)
                    }}</span>
                  </div></b-list-group-item
                > -->

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Created On:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.created_at)
                    }}</span>
                  </div></b-list-group-item
                >
              </b-list-group>
            </b-card-text>
          </template>

          <template v-else-if="this.policyCategory == 'provident-fund-policy'">
            <b-card-text>
              <p class="text-uppercase custom-text-disabled">
                Provident Fund Policy Details
              </p>
              <b-list-group flush>
                <b-list-group-item
                  ><div class="text-base">
                    Title:
                    <span class="text-body-2 font-weight-bold">{{
                      policyDetails?.title
                    }}</span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Salary Type:
                    <span class="text-body-2 font-weight-bold">
                      <b-badge class="ml-1" variant="light-success">
                        {{ formatSalaryType(policyDetails?.salary_type) }}
                      </b-badge>
                    </span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Employee Amount:
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="policyDetails?.amount_value_type == 'percentage'"
                      >
                        <b-badge class="ml-1" variant="light-primary">
                          {{
                            formatAmount(
                              policyDetails?.amount_value,
                              policyDetails?.amount_value_type
                            )
                          }}
                        </b-badge>
                      </template>
                      <template v-else>
                        <FormatCurrency
                          :currency="defaultCurrency"
                          :amount="policyDetails?.amount_value"
                        />
                      </template>
                    </span></div
                ></b-list-group-item>

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Employer Amount:
                    <span class="text-body-2 font-weight-bold">
                      <template
                        v-if="policyDetails?.amount_value_type == 'percentage'"
                      >
                        <b-badge class="ml-1" variant="light-primary">
                          {{
                            formatAmount(
                              policyDetails?.employer_amount_value,
                              policyDetails?.amount_value_type
                            )
                          }}
                        </b-badge>
                      </template>

                      <template v-else>
                        <FormatCurrency
                          :currency="defaultCurrency"
                          :amount="policyDetails?.employer_amount_value"
                        />
                      </template>
                    </span></div
                ></b-list-group-item>



                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Created On:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.created_at)
                    }}</span>
                  </div></b-list-group-item
                >
              </b-list-group>
            </b-card-text>
          </template>

          <template v-else-if="this.policyCategory == 'income-tax-policy'">
            <b-card-text>
              <p class="text-uppercase custom-text-disabled">
                Income Tax Policy Details
              </p>
              <b-list-group flush>
                <b-list-group-item
                  ><div class="text-base">
                    Title:
                    <span class="text-body-2 font-weight-bold">{{
                      policyDetails?.title
                    }}</span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Salary Type:
                    <span class="text-body-2 font-weight-bold">
                      <b-badge class="ml-1" variant="light-success">
                        {{ formatSalaryType(policyDetails?.salary_type) }}
                      </b-badge>
                    </span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Tax Free Income Amount:
                    <span class="text-body-2 font-weight-bold">
                      <FormatCurrency
                        :currency="defaultCurrency"
                        :amount="policyDetails?.tax_free_income_amount"
                      />
                    </span></div
                ></b-list-group-item>

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Created On:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.created_at)
                    }}</span>
                  </div></b-list-group-item
                >
              </b-list-group>
            </b-card-text>

            <div
              class="mt-1"
              v-if="policyDetails?.incomeTaxDetails?.data.length > 0"
            >
              <h5 class="text-capitalize">Income Tax Slabs</h5>

              <b-table
                :items="policyDetails?.incomeTaxDetails?.data"
                :fields="tableFields"
                class="mt-1"
              >
                <!-- Define table columns -->
                <template #cell(#)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(starting_amount)="data">
                  {{ data.item.starting_amount }}
                </template>
                <template #cell(ending_amount)="data">
                  {{ data.item.ending_amount }}
                </template>
                <template #cell(rate)="data">
                  {{ data.item.rate }}
                </template>
              </b-table>
            </div>
          </template>
          <template v-else-if="this.policyCategory == 'festival-bonus-policy'">
            <h4>Festival Bonus Policy Details</h4>
            <div class="mt-2">
              <h5 class="text-capitalize">Title</h5>
              <b-card-text class="font-weight-bold">
                {{ policyDetails?.title }}
              </b-card-text>
              <h5 class="text-capitalize">Salary Type</h5>
              <b-card-text>
                {{ formatSalaryType(policyDetails?.salary_type) }}
              </b-card-text>

              <h5 class="text-capitalize">Type</h5>
              <template v-if="policyDetails?.amount_value_type == 'flat'">
                <b-badge class="ml-1" variant="light-success">
                  {{ (policyDetails?.amount_value_type).toUpperCase() }}
                </b-badge>
              </template>
              <template v-else>
                <b-badge class="ml-1" variant="light-warning">
                  {{ (policyDetails?.amount_value_type).toUpperCase() }}
                </b-badge>
              </template>
              <h5 class="text-capitalize">Amount</h5>
              <b-card-text>
                <template
                  v-if="policyDetails?.amount_value_type == 'percentage'"
                >
                  <b-badge class="ml-1" variant="light-primary">
                    {{
                      formatAmount(
                        policyDetails?.amount_value,
                        policyDetails?.amount_value_type
                      )
                    }}
                  </b-badge>
                </template>
                <template v-else>
                  <FormatCurrency
                    :currency="defaultCurrency"
                    :amount="policyDetails?.amount_value"
                  />
                </template>
              </b-card-text>
              <h5 class="text-capitalize">Applicable After Months</h5>
              <b-card-text>
                {{ policyDetails?.applicable_after_months }}
              </b-card-text>
              <h5 class="text-capitalize">Created On</h5>
              <b-card-text>
                {{ formatDate(policyDetails?.created_at) }}
              </b-card-text>
            </div>
          </template>
          <template v-else-if="this.policyCategory == 'delay-fine-policy'">
            <b-card-text>
              <p class="text-uppercase custom-text-disabled">
                Delay Fine Policy Details
              </p>
              <b-list-group flush>
                <b-list-group-item
                  ><div class="text-base">
                    Title:
                    <span class="text-body-2 font-weight-bold">{{
                      policyDetails?.title
                    }}</span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Minimum Delay Days:
                    <span class="text-body-2 font-weight-bold">
                      <b-badge class="ml-1" variant="light-primary">
                        {{ policyDetails?.minimum_delay_days }}
                      </b-badge>
                    </span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Salary Deduction Days:
                    <span class="text-body-2 font-weight-bold">
                      <b-badge class="ml-1" variant="light-success">
                        {{ policyDetails?.salary_deduction_days }}
                      </b-badge>
                    </span>
                  </div></b-list-group-item
                >

                <b-list-group-item
                  ><div class="text-base font-weight-semibold">
                    Created On:
                    <span class="text-body-2 font-weight-bold">{{
                      formatDate(policyDetails?.created_at)
                    }}</span>
                  </div></b-list-group-item
                >
              </b-list-group>
            </b-card-text>
          </template>
        </b-card>
      </b-col>

      <b-col lg="7" cols="12">
        <b-card>
          <div>
            <div
              class="custom-search d-flex align-items-center justify-content-end"
            >
              <div
                class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
              >
                <b-form-input
                  v-on:keyup="onSearch"
                  v-model.lazy="searchTerm"
                  placeholder="Search..."
                  type="text"
                  class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
                />

                <template
                  v-if="$permissionAbility(PAYROLL_POLICY_ASSIGN, permissions)"
                >
                  <b-button
                    class="flex-shrink-0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-on:click="showPolicyAssignModal"
                  >
                    Add Member
                  </b-button>
                </template>
              </div>
            </div>

            <vue-good-table
              styleClass="vgt-table table-custom-style striped"
              :line-numbers="false"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :rows="rows"
              :sort-options="{
                enabled: true,
                multipleColumns: true,
                initialSortBy: [{ field: 'created_at', type: 'desc' }],
              }"
              :columns="columns"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'format_user_info'">
                  <UserCard :user="props?.row?.user?.data" />
                </span>

                <span v-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>

                      <template v-if="props?.row?.status">
                        <template
                          v-if="
                            $permissionAbility(
                              DEPARTMENT_EMPLOYEE_INACTIVE,
                              permissions
                            )
                          "
                        >
                          <b-dropdown-item
                            v-on:click="onInactive(props.row?.id)"
                          >
                            <feather-icon icon="XIcon" class="mr-50" />
                            <span>Inactive</span>
                          </b-dropdown-item>
                        </template>
                      </template>

                      <template v-else>
                        <template
                          v-if="
                            $permissionAbility(
                              DEPARTMENT_EMPLOYEE_ACTIVE,
                              permissions
                            )
                          "
                        >
                          <b-dropdown-item v-on:click="onActive(props.row?.id)">
                            <feather-icon icon="CheckIcon" class="mr-50" />
                            <span>Active</span>
                          </b-dropdown-item>
                        </template>
                      </template>
                    </b-dropdown>
                  </span>
                </span>

                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '25', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-policy-assign-user"
      centered
      title="Add Member To Policy"
      hide-footer
      @hidden="hiddenPolicyAssignModal"
      no-close-on-backdrop
    >
      <validation-observer ref="assignUserPolicy">
        <b-form v-on:submit.prevent="assignUserPolicyForm">
          <UserAssignPolicyForm
            @userAssignFromDataEvent="handleUserAssignFromData"
          />
          <template v-if="isUserPolicyAssignFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Assign
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </section>
</template>

  <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BSpinner,
  BTable,
  BListGroupItem,
  BListGroup,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import { salaryTypeConstants } from "@/helpers/constant/salaryTypeConstant";
import { amountValueTypeConstants } from "@/helpers/constant/amountValueTypeConstant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { PAYROLL_POLICY_ASSIGN } from "@/helpers/permissionsConstant";
import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
import UserCard from "@/layouts/components/UserCard.vue";
import FormatCurrency from "@/layouts/components/FormatCurrency.vue";
export default {
  name: "PayrollPolicyAssignUserDetails",
  components: {
    FormatCurrency,
    BListGroup,
    BListGroupItem,
    UserCard,
    BTable,
    UserAssignPolicyForm,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      PAYROLL_POLICY_ASSIGN,

      defaultCurrency: "",
      selectUserId: "",
      usersOption: [],

      policyCategory: "",
      policyDetails: "",
      incomeTaxSlabs: "",
      policyAssignData: "",
      salaryTypeConstants,
      amountValueTypeConstants,
      pageLength: 10,
      tableFields: [
        { key: "#", label: "#" },
        { key: "starting_amount", label: "Starting Amount" },
        { key: "ending_amount", label: "Ending Amount" },
        { key: "rate", label: "Rate (%)" },
      ],

      columns: [
        {
          label: "User",
          field: "format_user_info",
        },

        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatDate,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserPolicyAssignFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
      department: {},
      // items: [
      //   {
      //     age: 40,
      //     first_name: "Dickerson",
      //     last_name: "Macdonald",
      //     Occupation: "Job",
      //   },
      //   {
      //     age: 21,
      //     first_name: "Larsen",
      //     last_name: "Shaw",
      //     Occupation: "Job",
      //   },
      //   {
      //     age: 89,
      //     first_name: "Geneva",
      //     last_name: "Wilson",
      //     Occupation: "Bussiness",
      //   },
      //   {
      //     age: 38,
      //     first_name: "Jami",
      //     last_name: "Carney",
      //     Occupation: "Bussiness",
      //   },
      //   {
      //     age: 40,
      //     first_name: "James",
      //     last_name: "Thomson",
      //     Occupation: "Job",
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  watch: {
    selectUserId(newData, oldData) {
      if (newData) {
        const departmentOption = this.usersOption.find(
          (item) => item.id == newData
        ).department;

        if (departmentOption) {
          this.$swal({
            title: "Warning!",
            text: "Your selected user has a department do you want to change department?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.isDismissed) {
              this.resetModal();
            }
          });
        }
      }
    },
  },

  async created() {
    try {
      const [getSetting, getCurrencies] = await Promise.all([
        this.getSetting({
          select: "currency_id",
        }),
        this.getCurrencies(),
      ]);

      // getSetting
      const currencyId = getSetting?.data?.data?.currency_id;

      // getCurrencies
      const currencyData = getCurrencies?.data?.data?.filter((item) => {
        return item?.id === currencyId;
      });

      this.defaultCurrency = currencyData?.[0]?.currency;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getCurrencies() {
      return await this.$api.get("/api/currencies/all");
    },
    handleUserAssignFromData(data) {
      this.policyAssignData = data;
    },

    hiddenPolicyAssignModal() {
      this.$bvModal.hide("modal-policy-assign-user");
    },
    showPolicyAssignModal(value) {
      this.$bvModal.show("modal-policy-assign-user");
    },

    formatAmount(value, amountType) {
      if (amountType == "percentage") {
        return `${value} %`;
      } else if (amountType == "multiplied") {
        return `${value} X Hourly Salary`;
      }

      return value;
    },
    formatAmountType(value) {
      const amountType = amountValueTypeConstants.find(
        (item) => item.value === value
      );
      return amountType?.name;
    },

    formatSalaryType(value) {
      const salaryType = salaryTypeConstants.find(
        (item) => item.value === value
      );
      return salaryType?.name;
    },

    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getPolicyDetails() {
      const id = this.$route.params.id;
      if (this.policyCategory == "salary-policy") {
        return await this.$api.get(`/api/salary-policies/${id}`);
      }
      else if (this.policyCategory == "project-commission-policy") {
        return await this.$api.get(`/api/project-commission-policies/${id}`);
      }
      else if (this.policyCategory == "meal-policy") {
        return await this.$api.get(`/api/meal-policies/${id}`);
      }
      else if (this.policyCategory == "over-time-policy") {
        return await this.$api.get(`/api/over-time-policies/${id}`);
      }
      else if (this.policyCategory == "insurance-policy") {
        return await this.$api.get(`/api/insurance-policies/${id}`);
      }
      else if (this.policyCategory == "provident-fund-policy") {
          return await this.$api.get(`/api/provident-funds/${id}`);
      }
      else if (this.policyCategory == "income-tax-policy") {

        return await this.$api.get(
          `/api/income-tax-policies/${id}?include=incomeTaxDetails`
        );
      }
      else if (this.policyCategory == "festival-bonus-policy") {
        return await this.$api.get(`/api/festival-bonus-policies/${id}`);
      }
      else if (this.policyCategory == "delay-fine-policy") {
        return await this.$api.get(`/api/delay-fine-policies/${id}`);
      }
    },
    async getAssignedUserDetails(params) {
      const policyCategoryId = this.$route.params.policyCategoryId;

      const id = this.$route.params.id;

      return await this.$api.get(
        `api/payroll/policy-category/${policyCategoryId}/policy/${id}?include=user`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },
    async loadItems() {
      this.policyCategory = this.$route.params.policyCategoryName;

      try {
        const [policyDetails, assignedUserDetails] = await Promise.all([
          this.getPolicyDetails(),
          this.getAssignedUserDetails({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        this.policyDetails = policyDetails?.data?.data;

        console.log(this.policyDetails);

        const meta = assignedUserDetails?.data?.meta;
        this.rows = assignedUserDetails?.data?.data;

        console.log(this.rows)

        this.totalRecords = meta?.pagination?.total;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    assignUserPolicyForm: async function () {
      this.$refs.assignUserPolicy.validate().then(async (success) => {
        if (success) {
          let policyCategory = "";

          if (this.policyCategory == "salary-policy") {
            policyCategory = "Salary Policy";
          }
          else if (this.policyCategory == "project-commission-policy") {
            policyCategory = "Project Commission Policy";
          }
          else if (this.policyCategory == "meal-policy") {
            policyCategory = "Meal Policy";
          }
          else if (this.policyCategory == "over-time-policy") {
            policyCategory = "Over TIme Policy";
          }
          else if (this.policyCategory == "insurance-policy") {
            policyCategory = "Insurance Policy";
          }
          else if (this.policyCategory == "provident-fund-policy") {
            policyCategory = "Provident Fund Policy";
          }
          else if (this.policyCategory == "income-tax-policy") {
            policyCategory = "Income Tax Policy";
          } else if (this.policyCategory == "festival-bonus-policy") {
            policyCategory = "Festival Bonus Policy";
          } else if (this.policyCategory == "delay-fine-policy") {
            policyCategory = "Delay Fine Policy";
          }
          this.$swal({
            title: "Warning!",
            text: `The existing policies in the ${policyCategory} category for these users will be replaced by the action you're about to take!`,
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.$refs.assignUserPolicy.reset();

                this.isUserPolicyAssignFormSubmitLoading = true;
                const policyCategoryId = this.$route.params.policyCategoryId;

                const id = this.$route.params.id;
                const policyAssignEndpoint =
                  this.$route.params.policyCategoryName === "delay-fine-policy"
                    ? "delay-fine-policy/user-assign-policy"
                    : "user-assign-policy";

                await this.$api.post("/api/" + policyAssignEndpoint, {
                  policy_category_id: policyCategoryId,
                  policy_id: id,
                  applied_to: this.policyAssignData?.appliedTo,
                  is_all_department_selected:
                    this.policyAssignData?.isAllDeptSelected,
                  is_all_user_selected:
                    this.policyAssignData?.isAllUserSelected,
                  department_ids: this.policyAssignData?.departmentIds,
                  except_user_ids: this.policyAssignData?.exceptUserIds,
                  user_ids: this.policyAssignData?.userIds,
                });

                this.isUserPolicyAssignFormSubmitLoading = false;
                this.hiddenPolicyAssignModal();
                this.loadItems();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Policy Assigned Successfully ",
                  },
                });
              } catch (error) {
                this.isUserPolicyAssignFormSubmitLoading = false;
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.assignUserPolicy.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            } else {
              this.hiddenPolicyAssignModal();
            }
          });
        }
      });
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
