var render = function () {
  var _vm$policyDetails, _vm$policyDetails2, _vm$policyDetails3, _vm$policyDetails4, _vm$policyDetails5, _vm$policyDetails6, _vm$policyDetails7, _vm$policyDetails8, _vm$policyDetails9, _vm$policyDetails10, _vm$policyDetails11, _vm$policyDetails12, _vm$policyDetails13, _vm$policyDetails14, _vm$policyDetails15, _vm$policyDetails16, _vm$policyDetails17, _vm$policyDetails18, _vm$policyDetails19, _vm$policyDetails20, _vm$policyDetails21, _vm$policyDetails22, _vm$policyDetails23, _vm$policyDetails24, _vm$policyDetails25, _vm$policyDetails26, _vm$policyDetails27, _vm$policyDetails28, _vm$policyDetails29, _vm$policyDetails30, _vm$policyDetails31, _vm$policyDetails32, _vm$policyDetails33, _vm$policyDetails34, _vm$policyDetails35, _vm$policyDetails36, _vm$policyDetails37, _vm$policyDetails38, _vm$policyDetails39, _vm$policyDetails40, _vm$policyDetails41, _vm$policyDetails42, _vm$policyDetails43, _vm$policyDetails44, _vm$policyDetails45, _vm$policyDetails46, _vm$policyDetails47, _vm$policyDetails48, _vm$policyDetails49, _vm$policyDetails50, _vm$policyDetails51, _vm$policyDetails52, _vm$policyDetails53, _vm$policyDetails54, _vm$policyDetails55, _vm$policyDetails56, _vm$policyDetails57, _vm$policyDetails58, _vm$policyDetails59, _vm$policyDetails60, _vm$policyDetails61, _vm$policyDetails62, _vm$policyDetails63, _vm$policyDetails64, _vm$policyDetails65, _vm$policyDetails66, _vm$policyDetails67, _vm$policyDetails68, _vm$policyDetails69, _vm$policyDetails69$i, _vm$policyDetails70, _vm$policyDetails70$i, _vm$policyDetails71, _vm$policyDetails72, _vm$policyDetails73, _vm$policyDetails74, _vm$policyDetails75, _vm$policyDetails76, _vm$policyDetails77, _vm$policyDetails78, _vm$policyDetails79, _vm$policyDetails80, _vm$policyDetails81, _vm$policyDetails82, _vm$policyDetails83, _vm$policyDetails84, _vm$policyDetails85;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "5",
      "cols": "12"
    }
  }, [_c('b-card', [this.policyCategory == 'salary-policy' ? [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(" Salary Policy Details ")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails = _vm.policyDetails) === null || _vm$policyDetails === void 0 ? void 0 : _vm$policyDetails.title) + " %")])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Basic (%): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails2 = _vm.policyDetails) === null || _vm$policyDetails2 === void 0 ? void 0 : _vm$policyDetails2.basic) + " %")])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Rent (%): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails3 = _vm.policyDetails) === null || _vm$policyDetails3 === void 0 ? void 0 : _vm$policyDetails3.house_rent) + " %")])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Conveyance (%): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails4 = _vm.policyDetails) === null || _vm$policyDetails4 === void 0 ? void 0 : _vm$policyDetails4.conveyance) + " %")])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Medical (%): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails5 = _vm.policyDetails) === null || _vm$policyDetails5 === void 0 ? void 0 : _vm$policyDetails5.medical) + " %")])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Entertainment (%): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails6 = _vm.policyDetails) === null || _vm$policyDetails6 === void 0 ? void 0 : _vm$policyDetails6.entertainment) + " %")])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created On: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatDate((_vm$policyDetails7 = _vm.policyDetails) === null || _vm$policyDetails7 === void 0 ? void 0 : _vm$policyDetails7.created_at)))])])])], 1)], 1)] : this.policyCategory == 'project-commission-policy' ? [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(" Project Commission Policy Details ")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails8 = _vm.policyDetails) === null || _vm$policyDetails8 === void 0 ? void 0 : _vm$policyDetails8.title))])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Type: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails9 = _vm.policyDetails) === null || _vm$policyDetails9 === void 0 ? void 0 : _vm$policyDetails9.amount_value_type) == 'flat' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmountType((_vm$policyDetails10 = _vm.policyDetails) === null || _vm$policyDetails10 === void 0 ? void 0 : _vm$policyDetails10.amount_value_type)) + " ")])] : [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmountType((_vm$policyDetails11 = _vm.policyDetails) === null || _vm$policyDetails11 === void 0 ? void 0 : _vm$policyDetails11.amount_value_type)) + " ")])]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Amount: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails12 = _vm.policyDetails) === null || _vm$policyDetails12 === void 0 ? void 0 : _vm$policyDetails12.amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails13 = _vm.policyDetails) === null || _vm$policyDetails13 === void 0 ? void 0 : _vm$policyDetails13.amount_value, (_vm$policyDetails14 = _vm.policyDetails) === null || _vm$policyDetails14 === void 0 ? void 0 : _vm$policyDetails14.amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails15 = _vm.policyDetails) === null || _vm$policyDetails15 === void 0 ? void 0 : _vm$policyDetails15.amount_value
    }
  })]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created On: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatDate((_vm$policyDetails16 = _vm.policyDetails) === null || _vm$policyDetails16 === void 0 ? void 0 : _vm$policyDetails16.created_at)))])])])], 1)], 1)] : this.policyCategory == 'meal-policy' ? [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(" Meal Policy Details ")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails17 = _vm.policyDetails) === null || _vm$policyDetails17 === void 0 ? void 0 : _vm$policyDetails17.title))])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Type: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails18 = _vm.policyDetails) === null || _vm$policyDetails18 === void 0 ? void 0 : _vm$policyDetails18.amount_value_type) == 'flat' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmountType((_vm$policyDetails19 = _vm.policyDetails) === null || _vm$policyDetails19 === void 0 ? void 0 : _vm$policyDetails19.amount_value_type)) + " ")])] : [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmountType((_vm$policyDetails20 = _vm.policyDetails) === null || _vm$policyDetails20 === void 0 ? void 0 : _vm$policyDetails20.amount_value_type)) + " ")])]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Amount: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails21 = _vm.policyDetails) === null || _vm$policyDetails21 === void 0 ? void 0 : _vm$policyDetails21.amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails22 = _vm.policyDetails) === null || _vm$policyDetails22 === void 0 ? void 0 : _vm$policyDetails22.amount_value, (_vm$policyDetails23 = _vm.policyDetails) === null || _vm$policyDetails23 === void 0 ? void 0 : _vm$policyDetails23.amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails24 = _vm.policyDetails) === null || _vm$policyDetails24 === void 0 ? void 0 : _vm$policyDetails24.amount_value
    }
  })]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created On: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatDate((_vm$policyDetails25 = _vm.policyDetails) === null || _vm$policyDetails25 === void 0 ? void 0 : _vm$policyDetails25.created_at)))])])])], 1)], 1)] : this.policyCategory == 'over-time-policy' ? [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(" Over Time Policy Details ")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails26 = _vm.policyDetails) === null || _vm$policyDetails26 === void 0 ? void 0 : _vm$policyDetails26.title))])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Salary Type for Per Hour (Working Day): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails27 = _vm.policyDetails) === null || _vm$policyDetails27 === void 0 ? void 0 : _vm$policyDetails27.working_day_amount_value_type) == 'flat' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(((_vm$policyDetails28 = _vm.policyDetails) === null || _vm$policyDetails28 === void 0 ? void 0 : _vm$policyDetails28.working_day_amount_value_type).toUpperCase()) + " ")])] : [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" " + _vm._s(((_vm$policyDetails29 = _vm.policyDetails) === null || _vm$policyDetails29 === void 0 ? void 0 : _vm$policyDetails29.working_day_amount_value_type).toUpperCase()) + " ")])]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Salary Value for Per Hour (Working Day): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails30 = _vm.policyDetails) === null || _vm$policyDetails30 === void 0 ? void 0 : _vm$policyDetails30.working_day_amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails31 = _vm.policyDetails) === null || _vm$policyDetails31 === void 0 ? void 0 : _vm$policyDetails31.working_day_amount_value, (_vm$policyDetails32 = _vm.policyDetails) === null || _vm$policyDetails32 === void 0 ? void 0 : _vm$policyDetails32.working_day_amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails33 = _vm.policyDetails) === null || _vm$policyDetails33 === void 0 ? void 0 : _vm$policyDetails33.working_day_amount_value
    }
  })]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Salary Type for Per Hour (Off Day): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails34 = _vm.policyDetails) === null || _vm$policyDetails34 === void 0 ? void 0 : _vm$policyDetails34.off_day_amount_value_type) == 'flat' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(((_vm$policyDetails35 = _vm.policyDetails) === null || _vm$policyDetails35 === void 0 ? void 0 : _vm$policyDetails35.off_day_amount_value_type).toUpperCase()) + " ")])] : [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" " + _vm._s(((_vm$policyDetails36 = _vm.policyDetails) === null || _vm$policyDetails36 === void 0 ? void 0 : _vm$policyDetails36.off_day_amount_value_type).toUpperCase()) + " ")])]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Salary Value for Per Hour (Off Day): "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails37 = _vm.policyDetails) === null || _vm$policyDetails37 === void 0 ? void 0 : _vm$policyDetails37.off_day_amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails38 = _vm.policyDetails) === null || _vm$policyDetails38 === void 0 ? void 0 : _vm$policyDetails38.off_day_amount_value, (_vm$policyDetails39 = _vm.policyDetails) === null || _vm$policyDetails39 === void 0 ? void 0 : _vm$policyDetails39.off_day_amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails40 = _vm.policyDetails) === null || _vm$policyDetails40 === void 0 ? void 0 : _vm$policyDetails40.off_day_amount_value
    }
  })]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created On: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$policyDetails41 = _vm.policyDetails) === null || _vm$policyDetails41 === void 0 ? void 0 : _vm$policyDetails41.created_at)) + " ")])])])], 1)], 1)] : this.policyCategory == 'insurance-policy' ? [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(" Insurance Policy Details ")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails42 = _vm.policyDetails) === null || _vm$policyDetails42 === void 0 ? void 0 : _vm$policyDetails42.title))])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Salary Type: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatSalaryType((_vm$policyDetails43 = _vm.policyDetails) === null || _vm$policyDetails43 === void 0 ? void 0 : _vm$policyDetails43.salary_type)) + " ")])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Employee Amount: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails44 = _vm.policyDetails) === null || _vm$policyDetails44 === void 0 ? void 0 : _vm$policyDetails44.amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails45 = _vm.policyDetails) === null || _vm$policyDetails45 === void 0 ? void 0 : _vm$policyDetails45.amount_value, (_vm$policyDetails46 = _vm.policyDetails) === null || _vm$policyDetails46 === void 0 ? void 0 : _vm$policyDetails46.amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails47 = _vm.policyDetails) === null || _vm$policyDetails47 === void 0 ? void 0 : _vm$policyDetails47.amount_value
    }
  })]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Employer Amount: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails48 = _vm.policyDetails) === null || _vm$policyDetails48 === void 0 ? void 0 : _vm$policyDetails48.amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails49 = _vm.policyDetails) === null || _vm$policyDetails49 === void 0 ? void 0 : _vm$policyDetails49.employer_amount_value, (_vm$policyDetails50 = _vm.policyDetails) === null || _vm$policyDetails50 === void 0 ? void 0 : _vm$policyDetails50.amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails51 = _vm.policyDetails) === null || _vm$policyDetails51 === void 0 ? void 0 : _vm$policyDetails51.employer_amount_value
    }
  })]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Month: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails52 = _vm.policyDetails) === null || _vm$policyDetails52 === void 0 ? void 0 : _vm$policyDetails52.month))])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created On: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatDate((_vm$policyDetails53 = _vm.policyDetails) === null || _vm$policyDetails53 === void 0 ? void 0 : _vm$policyDetails53.created_at)))])])])], 1)], 1)] : this.policyCategory == 'provident-fund-policy' ? [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(" Provident Fund Policy Details ")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails54 = _vm.policyDetails) === null || _vm$policyDetails54 === void 0 ? void 0 : _vm$policyDetails54.title))])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Salary Type: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatSalaryType((_vm$policyDetails55 = _vm.policyDetails) === null || _vm$policyDetails55 === void 0 ? void 0 : _vm$policyDetails55.salary_type)) + " ")])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Employee Amount: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails56 = _vm.policyDetails) === null || _vm$policyDetails56 === void 0 ? void 0 : _vm$policyDetails56.amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails57 = _vm.policyDetails) === null || _vm$policyDetails57 === void 0 ? void 0 : _vm$policyDetails57.amount_value, (_vm$policyDetails58 = _vm.policyDetails) === null || _vm$policyDetails58 === void 0 ? void 0 : _vm$policyDetails58.amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails59 = _vm.policyDetails) === null || _vm$policyDetails59 === void 0 ? void 0 : _vm$policyDetails59.amount_value
    }
  })]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Employer Amount: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [((_vm$policyDetails60 = _vm.policyDetails) === null || _vm$policyDetails60 === void 0 ? void 0 : _vm$policyDetails60.amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails61 = _vm.policyDetails) === null || _vm$policyDetails61 === void 0 ? void 0 : _vm$policyDetails61.employer_amount_value, (_vm$policyDetails62 = _vm.policyDetails) === null || _vm$policyDetails62 === void 0 ? void 0 : _vm$policyDetails62.amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails63 = _vm.policyDetails) === null || _vm$policyDetails63 === void 0 ? void 0 : _vm$policyDetails63.employer_amount_value
    }
  })]], 2)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created On: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatDate((_vm$policyDetails64 = _vm.policyDetails) === null || _vm$policyDetails64 === void 0 ? void 0 : _vm$policyDetails64.created_at)))])])])], 1)], 1)] : this.policyCategory == 'income-tax-policy' ? [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(" Income Tax Policy Details ")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails65 = _vm.policyDetails) === null || _vm$policyDetails65 === void 0 ? void 0 : _vm$policyDetails65.title))])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Salary Type: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatSalaryType((_vm$policyDetails66 = _vm.policyDetails) === null || _vm$policyDetails66 === void 0 ? void 0 : _vm$policyDetails66.salary_type)) + " ")])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Tax Free Income Amount: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails67 = _vm.policyDetails) === null || _vm$policyDetails67 === void 0 ? void 0 : _vm$policyDetails67.tax_free_income_amount
    }
  })], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created On: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatDate((_vm$policyDetails68 = _vm.policyDetails) === null || _vm$policyDetails68 === void 0 ? void 0 : _vm$policyDetails68.created_at)))])])])], 1)], 1), ((_vm$policyDetails69 = _vm.policyDetails) === null || _vm$policyDetails69 === void 0 ? void 0 : (_vm$policyDetails69$i = _vm$policyDetails69.incomeTaxDetails) === null || _vm$policyDetails69$i === void 0 ? void 0 : _vm$policyDetails69$i.data.length) > 0 ? _c('div', {
    staticClass: "mt-1"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Income Tax Slabs")]), _c('b-table', {
    staticClass: "mt-1",
    attrs: {
      "items": (_vm$policyDetails70 = _vm.policyDetails) === null || _vm$policyDetails70 === void 0 ? void 0 : (_vm$policyDetails70$i = _vm$policyDetails70.incomeTaxDetails) === null || _vm$policyDetails70$i === void 0 ? void 0 : _vm$policyDetails70$i.data,
      "fields": _vm.tableFields
    },
    scopedSlots: _vm._u([{
      key: "cell(#)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(starting_amount)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.starting_amount) + " ")];
      }
    }, {
      key: "cell(ending_amount)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.ending_amount) + " ")];
      }
    }, {
      key: "cell(rate)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.rate) + " ")];
      }
    }], null, false, 3129577205)
  })], 1) : _vm._e()] : this.policyCategory == 'festival-bonus-policy' ? [_c('h4', [_vm._v("Festival Bonus Policy Details")]), _c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Title")]), _c('b-card-text', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s((_vm$policyDetails71 = _vm.policyDetails) === null || _vm$policyDetails71 === void 0 ? void 0 : _vm$policyDetails71.title) + " ")]), _c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Salary Type")]), _c('b-card-text', [_vm._v(" " + _vm._s(_vm.formatSalaryType((_vm$policyDetails72 = _vm.policyDetails) === null || _vm$policyDetails72 === void 0 ? void 0 : _vm$policyDetails72.salary_type)) + " ")]), _c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Type")]), ((_vm$policyDetails73 = _vm.policyDetails) === null || _vm$policyDetails73 === void 0 ? void 0 : _vm$policyDetails73.amount_value_type) == 'flat' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(((_vm$policyDetails74 = _vm.policyDetails) === null || _vm$policyDetails74 === void 0 ? void 0 : _vm$policyDetails74.amount_value_type).toUpperCase()) + " ")])] : [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" " + _vm._s(((_vm$policyDetails75 = _vm.policyDetails) === null || _vm$policyDetails75 === void 0 ? void 0 : _vm$policyDetails75.amount_value_type).toUpperCase()) + " ")])], _c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Amount")]), _c('b-card-text', [((_vm$policyDetails76 = _vm.policyDetails) === null || _vm$policyDetails76 === void 0 ? void 0 : _vm$policyDetails76.amount_value_type) == 'percentage' ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatAmount((_vm$policyDetails77 = _vm.policyDetails) === null || _vm$policyDetails77 === void 0 ? void 0 : _vm$policyDetails77.amount_value, (_vm$policyDetails78 = _vm.policyDetails) === null || _vm$policyDetails78 === void 0 ? void 0 : _vm$policyDetails78.amount_value_type)) + " ")])] : [_c('FormatCurrency', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "amount": (_vm$policyDetails79 = _vm.policyDetails) === null || _vm$policyDetails79 === void 0 ? void 0 : _vm$policyDetails79.amount_value
    }
  })]], 2), _c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Applicable After Months")]), _c('b-card-text', [_vm._v(" " + _vm._s((_vm$policyDetails80 = _vm.policyDetails) === null || _vm$policyDetails80 === void 0 ? void 0 : _vm$policyDetails80.applicable_after_months) + " ")]), _c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Created On")]), _c('b-card-text', [_vm._v(" " + _vm._s(_vm.formatDate((_vm$policyDetails81 = _vm.policyDetails) === null || _vm$policyDetails81 === void 0 ? void 0 : _vm$policyDetails81.created_at)) + " ")])], 2)] : this.policyCategory == 'delay-fine-policy' ? [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v(" Delay Fine Policy Details ")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('div', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$policyDetails82 = _vm.policyDetails) === null || _vm$policyDetails82 === void 0 ? void 0 : _vm$policyDetails82.title))])])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Minimum Delay Days: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$policyDetails83 = _vm.policyDetails) === null || _vm$policyDetails83 === void 0 ? void 0 : _vm$policyDetails83.minimum_delay_days) + " ")])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Salary Deduction Days: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s((_vm$policyDetails84 = _vm.policyDetails) === null || _vm$policyDetails84 === void 0 ? void 0 : _vm$policyDetails84.salary_deduction_days) + " ")])], 1)])]), _c('b-list-group-item', [_c('div', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created On: "), _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatDate((_vm$policyDetails85 = _vm.policyDetails) === null || _vm$policyDetails85 === void 0 ? void 0 : _vm$policyDetails85.created_at)))])])])], 1)], 1)] : _vm._e()], 2)], 1), _c('b-col', {
    attrs: {
      "lg": "7",
      "cols": "12"
    }
  }, [_c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  }), _vm.$permissionAbility(_vm.PAYROLL_POLICY_ASSIGN, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showPolicyAssignModal
    }
  }, [_vm._v(" Add Member ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row$user, _props$row2;
        return [props.column.field === 'format_user_info' ? _c('span', [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$user = _props$row.user) === null || _props$row$user === void 0 ? void 0 : _props$row$user.data
          }
        })], 1) : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [props !== null && props !== void 0 && (_props$row2 = props.row) !== null && _props$row2 !== void 0 && _props$row2.status ? [_vm.$permissionAbility(_vm.DEPARTMENT_EMPLOYEE_INACTIVE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              var _props$row3;
              return _vm.onInactive((_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "XIcon"
          }
        }), _c('span', [_vm._v("Inactive")])], 1)] : _vm._e()] : [_vm.$permissionAbility(_vm.DEPARTMENT_EMPLOYEE_ACTIVE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              var _props$row4;
              return _vm.onActive((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CheckIcon"
          }
        }), _c('span', [_vm._v("Active")])], 1)] : _vm._e()]], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-policy-assign-user",
      "centered": "",
      "title": "Add Member To Policy",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenPolicyAssignModal
    }
  }, [_c('validation-observer', {
    ref: "assignUserPolicy"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.assignUserPolicyForm($event);
      }
    }
  }, [_c('UserAssignPolicyForm', {
    on: {
      "userAssignFromDataEvent": _vm.handleUserAssignFromData
    }
  }), _vm.isUserPolicyAssignFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Assign ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }